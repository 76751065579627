import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ReleaseNote = makeShortcode("ReleaseNote");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <ReleaseNote date="2020-12-30" title="United Kingdom will no longer be flagged is_in_european_union" mdxType="ReleaseNote">
      <p>{`Effective January 5, 2021, the `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`is_in_european_union`}</inlineCode>
        {` flag will no longer be
marked true for locations in the United Kingdom. This change will be reflected
in the data in GeoLite2 databases and web services, GeoIP2 databases, GeoIP2
web services, and minFraud Insights and Factors services.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2020-10-20" title="New Output is_residential_proxy Released for GeoIP2 Insights Web Service, minFraud Insights, and minFraud Factors" mdxType="ReleaseNote">
      <p>{`We have released an additional output for our web services. `}
        <a {...{
          "href": "https://www.maxmind.com/en/geoip2-precision-services",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`GeoIP2
Insights`}</a>
        {`, `}
        <a {...{
          "href": "https://www.maxmind.com/en/solutions/minfraud-services",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`minFraud
Insights, and minFraud
Factors`}</a>
        {` customers can
now see whether an IP address is likely a `}
        <a {...{
          "href": "https://en.wikipedia.org/wiki/Proxy_server#Residential_proxy",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`residential
proxy`}</a>
        {`:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`/traits/is_residential_proxy`}</inlineCode>
          {` – This is true if the IP address is on a
suspected anonymizing network and belongs to a residential ISP. Otherwise, the
key is not included in the traits object.`}</li>

      </ul>
      <p>{`Our `}
        <a {...{
          "href": "/minfraud/evaluate-a-transaction#links-to-maxmind-client-apis",
          "parentName": "p"
        }}>{`client APIs`}</a>
        {`
have been updated to support these outputs so may need to refresh yours if you
are not interfacing directly with our REST API.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2020-10-12" title="IP Address Optional in minFraud Score, Insights, and Factors Services" mdxType="ReleaseNote">
      <p>{`Effective October 12, 2020 we are making the IP address input optional for all
minFraud Score, Insights, and Factors queries. If you use these minFraud
services for transactions where the IP address is not relevant (e.g. a phone
order placed through a call center), you no longer need to include an IP
address with your request. Note that the more data you send (including IP
addresses, when applicable), the better the scoring the minFraud service will
provide. See the `}
        <a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/4407964824859-Pass-Inputs-to-minFraud",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`relevant FAQ on our Support
Center`}</a>
        {`
for more information.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2020-10-06" title="IP Address Optional in minFraud Score, Insights, and Factors Services" mdxType="ReleaseNote">
      <p>{`Effective October 12, 2020 we are making the IP address input optional for all
minFraud Score, Insights, and Factors queries. If you use these minFraud
services for transactions where the IP address is not relevant (e.g. a phone
order placed through a call center), you no longer need to include an IP
address with your request. Note that the more data you send (including IP
addresses, when applicable), the better the scoring the minFraud service will
provide. See `}
        <a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/4407964824859-Pass-Inputs-to-minFraud",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`the relevant FAQ on our Support
Center`}</a>
        {`
for more information.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2020-09-14" title="Email Now Available as Custom Rules Parameter" mdxType="ReleaseNote">
      <p>{`We have released an additional input parameter for use with custom rules.
Customers of `}
        <a {...{
          "href": "/minfraud",
          "parentName": "p"
        }}>{`minFraud Score, minFraud Insights, and minFraud
Factors`}</a>
        {` can now use email address to serve as the basis when
creating a custom rule:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}>{`Email address: `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`/email/address/`}</inlineCode>
          {` – a valid email address or an MD5 of the
lowercased email used in the transaction.`}</li>

      </ul>
      <p>{`For more information on implementing custom rules, see the
`}
        <a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/4408801942811-Use-Custom-Rules-and-Dispositions",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`Custom Rules Guide on our Support Center`}</a>
        {`.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2020-09-10" title="Billing and Product/Service Permission Types for Standard Users" mdxType="ReleaseNote">
      <p>{`We have added a new permission category for the Standard User type. The
Product/Service permission grants a Standard User access to product and service
related functionality in the account portal without having access to user
management and billing functionality. For more information, consult our
`}
        <a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/4407107969947-Manage-Multiple-Users",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`Multi-User Account Access
Guide`}</a>
        {`.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2020-08-10" title="Update to minFraud service server locations" mdxType="ReleaseNote">
      <p>{`Effective August 17, 2020, we will no longer serve minFraud service queries
from our London-area servers. These queries will automatically be re-routed to
our US-East servers. You do not need to take any action as a result of this
change. Customers who have previously had queries routed to these servers may
see an increase of 100 – 150 ms in the response time for your queries.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2020-08-06" title="New minFraud Factors risk factors" mdxType="ReleaseNote">
      <p>{`We have released three additional risk-factor outputs. Customers of `}
        <a {...{
          "href": "/minfraud",
          "parentName": "p"
        }}>{`minFraud
Factors`}</a>
        {` can now access the following risk factors:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`/subscores/device`}</inlineCode>
          {` – Risk associated with the device.`}</li>


        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`/subscores/email_local_part`}</inlineCode>
          {` – Risk associated with the part of the email
before the @ symbol.`}</li>


        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`/subscores/shipping_address`}</inlineCode>
          {` – Risk associated with the shipping address.`}</li>

      </ul>
      <p>{`Our `}
        <a {...{
          "href": "/minfraud/evaluate-a-transaction#links-to-maxmind-client-apis",
          "parentName": "p"
        }}>{`client APIs`}</a>
        {`
have been updated to support these outputs so may need to refresh yours if you
are not interfacing directly with our rest API.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2020-06-24" title="Report transaction support in minFraud client APIs" mdxType="ReleaseNote">
      <p>{`The `}
        <a {...{
          "href": "/minfraud",
          "parentName": "p"
        }}>{`minFraud Score, Insights, and Factors`}</a>
        {` client APIs now support
sending transaction feedback to our `}
        <a {...{
          "href": "/minfraud/report-a-transaction",
          "parentName": "p"
        }}>{`Report Transaction
endpoint`}</a>
        {`. This will allow you to more
easily report fraud, suspected fraud, spam/abuse, and false positives to
improve scoring. You can find more details on using this functionality in the
`}
        <a {...{
          "href": "/minfraud/evaluate-a-transaction#links-to-maxmind-client-apis",
          "parentName": "p"
        }}>{`documentation for the specific client API you
use`}</a>
        {`.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2020-06-22" title="FIDO2 Support for Two-Factor Authentication" mdxType="ReleaseNote">
      <p>{`We've updated our Two-Factor Authentication (2FA) implementation to the `}
        <a {...{
          "href": "https://fidoalliance.org/fido2/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`FIDO2
standard`}</a>
        {`, which supports more web browsers
and more types of security keys (e.g. supported biometrics keys). See our
`}
        <a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/1260803946470-Set-Up-Two-Factor-Authentication",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`Two-Factor Authentication (2FA) User Guide for more
information`}</a>
        {`.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2020-06-15" title="Data Changes to GeoIP Legacy and minFraud Legacy Web Services" mdxType="ReleaseNote">
      <p>{`We are planning to make a number of data changes to the minFraud Legacy
Standard and Premium, and GeoIP Legacy City and Insights (formerly Omni) web
services at the end of May 2022. Please see our `}
        <a {...{
          "href": "https://blog.maxmind.com/2020/06/data-changes-to-geoip-legacy-and-minfraud-legacy-web-services-in-may-2022/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`blog
post`}</a>
        {`
for more information, and how to prepare.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2020-05-14" title="Anonymous IP flags as parameters for custom rules" mdxType="ReleaseNote">
      <p>{`Customers of `}
        <a {...{
          "href": "/minfraud",
          "parentName": "p"
        }}>{`minFraud Insights and minFraud Factors`}</a>
        {` can now use
the following `}
        <a {...{
          "href": "/geoip/docs/web-services/responses/#schema--response--traits",
          "parentName": "p"
        }}>{`Anonymous IP outputs`}</a>
        {` as parameters to
serve as the basis when creating a custom rule:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`/ip_address/traits/is_anonymous`}</inlineCode></li>


        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`/ip_address/traits/is_anonymous_vpn`}</inlineCode></li>


        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`/ip_address/traits/is_hosting_provider`}</inlineCode></li>


        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`/ip_address/traits/is_public_proxy`}</inlineCode></li>


        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`/ip_address/traits/is_tor_exit_node`}</inlineCode></li>

      </ul>
    </ReleaseNote>
    <ReleaseNote date="2020-05-08" title="Retirement of legacy minFraud SOAP API" mdxType="ReleaseNote">
      <p>{`On May 8, 2020, we discontinued our legacy minFraud SOAP API in order to focus
our development on our newer current APIs. We initially `}
        <a {...{
          "href": "https://blog.maxmind.com/2019/04/important-updates-about-tls-v1-0-v1-1-unencrypted-http-requests-and-the-legacy-minfraud-soap-api/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`announced this
retirement in April
2019`}</a>
        {`.`}</p>
      <p>{`Other `}
        <a {...{
          "href": "/minfraud/minfraud-legacy",
          "parentName": "p"
        }}>{`client APIs for legacy minFraud services`}</a>
        {` (not
recommended) will continue to be supported. We recommend either integrating with
the `}
        <a {...{
          "href": "/minfraud/minfraud-legacy",
          "parentName": "p"
        }}>{`legacy minFraud service directly`}</a>
        {`, or using our
`}
        <a {...{
          "href": "/minfraud",
          "parentName": "p"
        }}>{`current minFraud services`}</a>
        {`.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2020-04-27" title="Billing fields and is disposable email as input parameters for custom rules" mdxType="ReleaseNote">
      <p>{`We have released additional input parameters for use with custom rules.
Customers of `}
        <a {...{
          "href": "/minfraud",
          "parentName": "p"
        }}>{`minFraud Score, minFraud Insights, and minFraud
Factors`}</a>
        {` can now use the following inputs to serve as the basis when
creating a custom rule:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`/billing/address`}</inlineCode>
          {` – The first line of the user's billing address.`}</li>


        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`/billing/address_2`}</inlineCode>
          {` – The second line of the user's billing address.`}</li>


        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`/email/is_disposable`}</inlineCode>
          {` – This field is true if MaxMind believes that the email
address is from a disposable email provider. It is false if the address is not
from a known disposable email provider.`}</li>

      </ul>
    </ReleaseNote>
    <ReleaseNote date="2020-04-06" title="New /credit_card/is_business output" mdxType="ReleaseNote">
      <p>{`The `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`/credit_card/is_business`}</inlineCode>
        {` output is now available in minFraud Insights and
Factors. This field is `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`true`}</inlineCode>
        {` if the issuer ID number is for a business card.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2020-03-24" title="New /email/domain/first_seen output" mdxType="ReleaseNote">
      <p>{`The `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`/email/domain/first_seen`}</inlineCode>
        {` output is now available in minFraud Insights and
Factors. This output is a date string (e.g. 2020-03-24) indicating the date the
email address domain was first seen by MaxMind. The earliest date that may be
returned is January 1, 2019.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2020-03-02" title="minFraud Query Form" mdxType="ReleaseNote">
      <p>{`Users of the minFraud Score, Insights, and Factors services can now manually
submit minFraud queries (with a subset of inputs) through the new `}
        <a {...{
          "href": "https://www.maxmind.com/en/accounts/current/minfraud-interactive/query-form",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`minFraud
Query
Form`}</a>
        {`
`}
        {`[`}
        {`login required`}
        {`]`}
        {`. The results of the query are displayed in our `}
        <a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/4408762136603-Review-a-minFraud-Transaction",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`minFraud
Interactive`}</a>
        {`
user interface.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2020-02-19" title="New /email/is_disposable output" mdxType="ReleaseNote">
      <p>{`The `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`/email/is_disposable`}</inlineCode>
        {` output is now available in minFraud Insights and
Factors. The output is a boolean indicating whether the email address is from a
disposable email provider.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2020-02-04" title="More outputs on transaction details screen" mdxType="ReleaseNote">
      <p>{`We've added the following outputs to the Transaction Details screen in
`}
        <a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/4408762136603-Review-a-minFraud-Transaction",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`minFraud
Interactive`}</a>
        {`
to assist you with manual review of minFraud transactions:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}>{`Subdivision name`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Registered country name`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Represented country name`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Represented country type`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Country, city, and subdivision confidence`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`ISP`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Organization`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Domain`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Anonymous IP flags`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`User count`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Static IP score`}</li>

      </ul>
      <p>{`You can find descriptions of these outputs
`}
        <a {...{
          "href": "/geoip/docs/web-services/responses",
          "parentName": "p"
        }}>{`here`}</a>
        {`.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2020-01-31" title="Retirement of legacy IIN (BIN) API" mdxType="ReleaseNote">
      <p>{`On January 31, 2020, we discontinued our legacy IIN (BIN) API in order to focus
development and maintenance efforts on our core services. The affected endpoint
is `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`/app/bin_http`}</inlineCode>
        {`.`}</p>
      <p>{`We continue to support the IIN (BIN)
`}
        <a {...{
          "href": "https://www.maxmind.com/en/accounts/current/minfraud/iin/lookup",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`manual look-up form on our website`}</a>
        {`
`}
        {`[`}
        {`login required`}
        {`]`}
        {`.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2020-01-29" title="Tag transactions from transaction details screen" mdxType="ReleaseNote">
      <p>{`minFraud service users can now tag transactions (e.g. chargeback, not fraud,
spam/abuse, suspected fraud) directly from the Transaction Details screen for
an individual transaction in `}
        <a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/4408762136603-Review-a-minFraud-Transaction",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`minFraud
Interactive`}</a>
        {`.`}</p>
      <p>{`Tagging transactions allows us to detect 10-50% more fraud for you. You may also
tag transactions outside of minFraud Interactive by using
`}
        <a {...{
          "href": "https://www.maxmind.com/en/accounts/current/minfraud/report-transactions",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`this form`}</a>
        {`.`}</p>
    </ReleaseNote>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      